<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12">
          <h2 class="title font-weight-medium pb-5">Seting Jam Masuk</h2>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
              </template>
            </v-snackbar>
            <v-card>
              <v-card-text>
                <v-container>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    Jam Masuk Guru
                    <input type="time" v-model="masuk" format="hh:mm"  placeholder="Jam Masuk">
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    Jam Pulang Guru
                    <input type="time" v-model="pulang" format="hh:mm"  placeholder="Jam Pulang">
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    Jam Masuk Siswa
                    <input type="time" v-model="masuk_siswa" format="hh:mm"  placeholder="Jam Masuk">
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    Jam Pulang Siswa
                    <input type="time" v-model="pulang_siswa" format="hh:mm"  placeholder="Jam Pulang">
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Kode QR" v-model="kode_absen" required color="green"></v-text-field>
                  </v-col>
                  <v-list-item-avatar size="120">
                    <img :src="file_qr_lok"/>
                  </v-list-item-avatar>
                </v-row>
              </v-container>
              <small>*wajib diisi!!!</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" @click="saveData" dark>Save</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false, kode_absen:'',masuk:0,pulang:0,
    text: 'OK', url_logo:'',masuk_siswa:0,pulang_siswa:0,
    timeout: 2000,jam:0,file_qr_lok:'',
    cariNama:'', npsn:'',
    loading: true,
    lsData : [],
    ftData : [],
    ddKatMR : [],
    katmrID :1,
    dialog : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    hp: '',
    alamat: '',nama_kepsek: '',nip_kepsek: '',
    email: '',
    id: 0,
    files: null,
    fileGbr: '',
    fileGbr2: ''
  }),
  components:{
    Navbar
  },
  methods: {
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
    },
    
    reLogin(){
      const dataku = {
        user_id: this.$store.state.user.user_id,
      }
      axios
        .post(api + 'reLogin', dataku)
        .then(response => {
          console.log('relogin..................')
          if (response.data.cek === 1) {
            this.text = 'Berhasil'
            this.snackbar=true
            localStorage.setItem('Usr-jidismart', JSON.stringify(response.data.data))
            localStorage.setItem('nav-jidismart', JSON.stringify(response.data.navMenu))
            this.$store.commit('SET_USER', response.data.data)
            this.$store.commit('SET_menuNav', JSON.stringify(response.data.navMenu))
            window.location = '/'
          } else {
            this.text = 'Salah'
            this.snackbar =true
          }
        })
    },
    saveData () {
      if (this.kode_absen === '') {
        this.snackbar =true
      } else {
        console.log('cek jam ', this.jam)
        const dataku = {
          jam_masuk_guru: this.masuk,
          jam_pulang_guru: this.pulang,
          jam_masuk_siswa: this.masuk_siswa,
          jam_pulang_siswa: this.pulang_siswa,
          kode_absen: this.kode_absen
        }
        axios
          .post(api + 'saveSet_jam&sk_id=' + this.$store.state.user.sk_id, dataku)
          .then(response => {
            console.log('cek awal ', dataku)
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
            } else {
              this.text = 'Error!!!'
              this.snackbar=true
            }
          })
      }
    },
    async fnData () {
      axios
        .get(api + 'getSK&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            //this.ftData = response.data.data
            let datax = response.data.data[0]
            console.log('cek hasil', datax) 
            this.masuk = datax.jam_masuk_guru
            this.pulang = datax.jam_pulang_guru
            this.masuk_siswa = datax.jam_masuk_siswa
            this.pulang_siswa = datax.jam_pulang_siswa
            this.kode_absen = datax.kode_absen
            this.file_qr_lok = datax.file_qr_lok
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      //console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.userID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.nama = dtEdit.nama
      this.email = dtEdit.email
      this.hp = dtEdit.hp
      this.pwd = dtEdit.pwd
      this.ugID = dtEdit.ugID
      this.nama_kepsek = dtEdit.nama_kepsek
      this.nip_kepsek = dtEdit.nip_kepsek
      this.dialog = true
    },
    clsForm () {
      this.nama = ''
      this.hp = ''
      this.alamat = ''
      this.hpp = 0
      this.id = 0
    },

  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
